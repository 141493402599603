<template>
  <div class="border-bottom graphiteFont" v-once>
    <div class="positionHeader">
      <v-toolbar :elevation="1" class="body-2 fixed-bar" width="100%">
        <v-responsive class="mx-auto" max-width="1180">
        <v-row no-gutters class="d-flex align-center h-66 mx-auto justify-space-between max-width-1180 ">
          <div class="d-flex align-center" @click="$router.push('/').catch(() => {})">
            <img
              height="40px"
              width="40px"
              class="mr-3"
              src="@/assets/images/amogashortlogo.svg"
              alt="amogaLogo"
            /><span class="font-weight-bold body-2">AMOGA</span>
          </div>

          <div class="d-flex align-center w-50 body-2 font-weight-bold">
            <span class="px-4" v-for="item in headerTitles" :key="item"><a class="text--primary">{{item}}</a></span>  
          </div>

          <div class="d-flex align-center font-weight-bold">
            <div>
              <!--  -->
              <v-btn height="36" min-width="80"
                @click="$router.push('/dashboard').catch(() => {})"
                depressed
                class="white--text text-capitalize body-2"
                color="blue accent-3"
                >Login</v-btn>
            </div>
          </div>
        </v-row>
        </v-responsive>
        <!-- <v-switch v-model="$vuetify.theme.dark"></v-switch> -->
      </v-toolbar>
    </div>
     <v-dialog v-model="loginDialog" min-height="100px" max-width="700px">
       <v-card height="100">
         <label>Login</label>
       </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      priceContent: false,
      learnContent: false,
      individualsContent: false,
      businessContent: false,
      developersContent: false,
      companyContent: false,
      priceContent: false,
      headerTitles:[ "Price","Learn","Individuals","Businesses","Developers","Company"],
      loginDialog: false,
    };
  },
  props: {
    scroll: {
      type: Number
    }
  },
  methods: {
    call(){
      debugger 
    this.loginDialog = true}
  },
};
</script>
<style>
.fixed-bar {
  position: fixed !important;
  position: -webkit-fixed !important; /* for Safari */
  z-index: 1;
}
</style>